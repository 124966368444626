<template>
  <div>
    <b-container fluid>
      <b-row class="my-1">
        <!-- <b-col>
          <b-form-select :options="options0" v-model="selectedOpt0" size="sm"></b-form-select>
        </b-col> -->
        <b-col>
          <b-form-select
            :options="options1"
            v-model="selectedOpt1"
            @change="changeOpt1"
            size="sm"
            style="color:#e83e8c "
          ></b-form-select>
        </b-col>
        <b-col>
          <b-form-select
            :options="options2"
            v-model="selectedOpt2"
            @change="changeOpt2"
            size="sm"
            style="color:#ffc107"
          ></b-form-select>
        </b-col>
        <b-col>
          <b-form-select
            :options="options3"
            v-model="selectedOpt3"
            @change="changeOpt3"
            size="sm"
            style="color:#28a745"
          ></b-form-select>
        </b-col>
        <b-col v-if="isSearch" cols="3">
          <b-form-input
            placeholder="강좌 No.(A01010101)"
            size="sm"
            v-model="lcNo"
            @input="changeOpt4"
          ></b-form-input>
        </b-col>
        <b-col cols="2">
          개수
          <span class="badge badge-info badgeNum">
            {{ this.selectedCnt }}
          </span>
        </b-col>
        <b-col class="d-flex flex-row-reverse">
          <b-button @click="clearInput" size="sm">초기화</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { getCategory } from '../../api/lecture'
export default {
  name: 'lecture-select',
  props: {
    isSearch: {
      type: Boolean,
      default: false
    },
    cate1: {},
    cate2: {},
    cate3: {},
    selectedCnt: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      options0: [
        { value: 'A', text: '투자스쿨' },
        { value: 'B', text: '종목스쿨', disabled: true }
      ],
      options1: [],
      options2: [],
      options3: [],
      selectedOpt0: 'A',
      selectedOpt1: null,
      selectedOpt2: null,
      selectedOpt3: null,

      treeData: [],
      depth2tree: [],
      depth3tree: [],
      lcNo: ''
    }
  },
  watch: {
    cate1() {
      this.selectedOpt1 = this.cate1
    },
    cate2() {
      this.selectedOpt2 = this.cate2
    },
    cate3() {
      this.selectedOpt3 = this.cate3
    }
  },
  methods: {
    clearInput() {
      this.selectedOpt0 = 'A'
      this.selectedOpt1 = null
      this.selectedOpt2 = null
      this.selectedOpt3 = null
      this.lcNo = ''
      window.location.href = window.location.href.split('?')[0]
    },

    create1Depth(tree) {
      tree.forEach(value => {
        this.options1.push({
          value: value.lc_no,
          text: `${value.title}-${value.lc_no}`,
          id: value.ca_sn
        })
      })
    },
    create2Depth(tree) {
      tree.forEach(value => {
        this.options2.push({
          value: value.lc_no,
          text: `${value.title}-${value.lc_no}`,
          id: value.ca_sn
        })
      })
    },
    create3Depth(tree) {
      tree.forEach(value => {
        this.options3.push({
          value: value.lc_no,
          text: `${value.title}-${value.lc_no}`,
          id: value.ca_sn
        })
      })
    },

    changeOpt1() {
      this.selectedOpt2 = null
      this.selectedOpt3 = null
      this.lcNo = ''
      this.options2 = []
      this.options3 = []

      if (this.selectedOpt1) {
        const response = this.options1.find(element => {
          if (element.value == this.selectedOpt1) return element
        })
        this.depth2tree = this.treeData.find(element => {
          if (element.ca_sn === response.id) return element
        })
        var temp = this.depth2tree.children
        this.create2Depth(temp)

        const result = this.options1.find(value => value.value == this.selectedOpt1)
        this.$emit('updateOpt1', result)
        this.$emit('updateOpt2', '')
        this.$emit('updateOpt3', { value: '', id: -1 })
      }
    },
    changeOpt2() {
      this.selectedOpt3 = null
      this.lcNo = ''
      this.options3 = []

      if (this.selectedOpt2) {
        const response = this.options2.find(element => {
          if (element.value == this.selectedOpt2) return element
        })
        this.depth3tree = this.depth2tree.children.find(element => {
          if (element.ca_sn === response.id) return element
        })
        var temp = this.depth3tree.children
        this.create3Depth(temp)

        const result = this.options2.find(value => value.value == this.selectedOpt2)
        this.$emit('updateOpt2', result)
        this.$emit('updateOpt3', { value: '', id: -1 })
      }
    },
    changeOpt3() {
      if (this.selectedOpt3) {
        const result = this.options3.find(value => value.value == this.selectedOpt3)
        if (result) {
          this.$emit('updateOpt3', result)
        }
      }
    },
    changeOpt4() {
      this.$emit('updateLcNo', this.lcNo)
    },

    async initCategory() {
      var resposeData = await getCategory()
      if (resposeData) {
        this.treeData = resposeData.data
        this.create1Depth(this.treeData)
        this.callEditCategory()
      } else {
        alert('GetCategory failed')
      }
    },
    callEditCategory() {
      console.log(this.selectedOpt1, this.selectedOpt2, this.selectedOpt3)
      if (this.selectedOpt1) {
        const response = this.options1.find(element => {
          if (element.value == this.selectedOpt1) return element
        })
        this.depth2tree = this.treeData.find(element => {
          if (element.ca_sn === response.id) return element
        })
        var temp = this.depth2tree.children
        this.create2Depth(temp)

        if (this.selectedOpt2) {
          const response = this.options2.find(element => {
            if (element.value == this.selectedOpt2) return element
          })
          this.depth3tree = this.depth2tree.children.find(element => {
            if (element.ca_sn === response.id) return element
          })
          temp = this.depth3tree.children
          this.create3Depth(temp)
        }
      }
    }
  },
  created() {
    this.initCategory()
  }
}
</script>
